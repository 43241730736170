import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-reload',
  template: `
    <p>
      app-common-reload works!
    </p>
  `,
  styles: [
  ]
})
@Injectable()
export class AppReloadComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit(): void {
  }

  reloadComponent(self:boolean,urlToNavigateTo ?:string){
     //skipLocationChange:true means dont update the url to / when navigating
    console.log("Current route :",this.router.url);
    console.log("Navigation Url :",urlToNavigateTo);
    const url=self ? this.router.url :urlToNavigateTo;
    this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
        this.router.navigate([`${url}`]).then(()=>{
          console.log(`After navigation :${url}`)
        })
      })
  }

  
}