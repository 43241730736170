import { Injectable } from "@angular/core";
import {
  OidcClient,
  TokenResponse,
} from "@pingidentity-developers-experience/ping-oidc-client-sdk";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { IUserInfo } from "./user-info.model";
import nativePlugin from "src/plugins/nativePlugin";
import PingPlugin from "src/plugins/PingPlugin";
import { Preferences } from "@capacitor/preferences";
import { StorageConstants } from "../shared/utilities/storage-constants";

@Injectable({
  providedIn: "root",
})
export class PingAuthService {
  static oidcClient: Promise<OidcClient>;
  static pingToken: string;

  static async initializePingAuth() {
    this.oidcClient = OidcClient.initializeFromOpenIdConfig(
      "https://auth.pingone.com/d85932c9-e4ac-47b1-88fb-cde2671d1e02/as",
      environment.pingClientOptions
    );
    console.log("this.oidcClient", this.oidcClient);
    await this.getUserInfoFromToken()
    // localStorage.setItem("oidcClient", JSON.stringify(this.oidcClient));
    return this.oidcClient;
  }
  static async initializeAndroidPingAuth() {
    try {
      console.log("initializeAndroidPingAuth called");
      await PingPlugin.initializePingAuth({}).then((data) => {
        //await this.getAndroidAccessToken();
        if (data != null) {
          console.log("access Token data", data.accessToken);
          PingAuthService.pingToken = data.accessToken;
          AuthService.token.access_token = data.accessToken;
        }
      });
    } catch (error) {
      console.error("Error initializing PingAuth", error);
    }
  }

  static async getValidToken(): Promise<string> {
    // Check if pingPlatform is defined and has the 'is' method
    if (AuthService.pingPlatform && AuthService.pingPlatform.is) {
      if (AuthService.pingPlatform.is("android")) {
        console.log("Auth Service getValidToken called - Android");
        if (AuthService._isAuthenticated.value === true) {
          await PingPlugin.getAndroidAccessToken({}).then((data) => {
            if (data != null) {
              console.log(
                "access Token data from getValidToken method",
                data.accessToken
              );
              PingAuthService.pingToken = data.accessToken;
              AuthService.token.access_token = data.accessToken;
            }
          });
        }
        return PingAuthService.pingToken;
      } else if (AuthService.pingPlatform.is("desktop") || AuthService.pingPlatform.is("mobileweb")) {
        console.log("Auth Service getValidToken called");
        var token: TokenResponse;
        if (await (await this.oidcClient).hasToken()) {
          token = await (await this.oidcClient).getToken();
          try {
            console.log("this.token", token);
            console.log("this.oidcClient", this.oidcClient);
            await this.getUserInfoFromToken();
            //this.userInfo = await oidcClient.fetchUserInfo();
            await AuthService.setAuthenticated(true);
          } catch (error) {
            console.log("Auth Service getValidToken called Fail");
            console.log("this.oidcClient", this.oidcClient);
            const refreshedToken = await this.refreshToken();
            console.log("this.refreshdToken", refreshedToken);
            token = refreshedToken || null;
            console.log("this.token", token);
            await this.getUserInfoFromToken();
            await AuthService.setAuthenticated(true);
          }
          AuthService.token = token;
        } else {
          console.log("Invalid code or state. Redirecting to login page.");
          await AuthService.setAuthenticated(false);
          await AuthService.signOut();
          // authService.router.navigate(["/login"]);
        }
        console.log("Auth Service getValidToken called - Web");
        if (this.oidcClient === undefined) {
          await this.initializePingAuth();
        }
        return token?.access_token;
      }
    }
  }

  static async refreshToken(): Promise<TokenResponse | null> {
    if (AuthService.isRefreshingToken) {
      return AuthService.refreshTokenPromise;
    }

    AuthService.isRefreshingToken = true;
    AuthService.refreshTokenPromise = (async () => {
      try {
        const refreshedToken = await (await this.oidcClient).refreshToken();
        AuthService.token = refreshedToken || null;
        return AuthService.token;
      } catch (error) {
        console.error("Error refreshing token", error);
        return null;
      } finally {
        AuthService.isRefreshingToken = false;
        AuthService.refreshTokenPromise = null;
      }
    })();

    return AuthService.refreshTokenPromise;
  }

  static async handleAuthCallback(authService: AuthService) {
    console.log("Auth Service handleAuthCallback called");
    // console.log("authService", authService);
    await PingAuthService.getValidToken();
    console.log(
      "Auth Service handleAuthCallback called - token value is : " +
        AuthService.token.access_token
    );
    await authService.setUserProfile();
    // this.router.navigate([""]);
  }

  static async getUserInfoFromToken() {
    if (AuthService.pingPlatform && AuthService.pingPlatform.is) {
      if (AuthService.pingPlatform.is("android")) {
        console.log("getUserInfoFromToken called Android");
        await PingPlugin.fetchUserInfo({}).then(
          (response: { userInfo: Object }) => {
            console.log("userInfo", response.userInfo);
            var name = null;
            if (response.userInfo["given_name"] != null) {
              name = response.userInfo["given_name"];
            }
            if (response.userInfo["middle_name"] != null) {
              name = name + " " + response.userInfo["middle_name"];
            }
            if (response.userInfo["family_name"] != null) {
              name = name + " " + response.userInfo["family_name"];
            }
            AuthService.userInfo = {
              name: name,
              identityKey: response.userInfo["identityKey"],
              user_name: response.userInfo["preferred_username"],
              preferred_username: response.userInfo["preferred_username"],
              given_name: response.userInfo["given_name"],
            };
            Preferences.set({
              key: StorageConstants.USER_IDENTITY_KEY,
              value: AuthService.userInfo.identityKey,
            });
            console.log(
              "AuthService.userInfo.givenName",
              response.userInfo["given_name"]
            );
          }
        );
      } else if (
        AuthService.pingPlatform.is("desktop") ||
        AuthService.pingPlatform.is("mobileweb")
      ) {
        await (await this.oidcClient)
          ?.fetchUserInfo()
          .then((userInfo: IUserInfo) => {
            AuthService.userInfo = userInfo;
             Preferences.set({
              key: StorageConstants.USER_IDENTITY_KEY,
              value: AuthService.userInfo.identityKey,
            });
            console.log("Authservice.userinfo from Ping-auth.service");
          });
      }
      return AuthService.userInfo;
    }
  }
}
