import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { LandingPage } from "./landing.page";
import { Routes, RouterModule } from "@angular/router";
import { SharedComponentsModule } from "../../shared/shared-components.module";
import { PayAuthGuardService } from "../../auth/pay-auth-guard.service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { QaAuthService } from "../../auth/qa-auth-service";
import { UserAgreementResolver } from "src/app/login.page.resolver";
import { PingAuthGuardService } from "src/app/auth/ping-auth-guard.service";
const routes: Routes = [
  {
    path: "",
    component: LandingPage,
    resolve: {
      accepted: UserAgreementResolver,
    },
    children: [
      {
        path: "",
        canActivate: [PingAuthGuardService],
        loadChildren: () =>
          import("../home/home.module").then((h) => h.HomePageModule),
      },
      {
        path: "contact",
        loadChildren: () =>
          import("../contact/contact.module").then(
            (cp) => cp.ContactPageModule
          ),
      },
      {
        path: "customize-home",
        loadChildren: () =>
          import("../customize-home/customize-home.module").then(
            (ch) => ch.CustomizeHomePageModule
          ),
      },
      {
        path: "preview-home",
        loadChildren: () =>
          import("../preview-home/preview-home.module").then(
            (ph) => ph.PreviewHomePageModule
          ),
      },
      {
        path: "maps",
        loadChildren: () =>
          import("../maps/maps.module").then((mp) => mp.MapsPageModule),
      },
      {
        path: "more",
        loadChildren: () =>
          import("../more/more.module").then((m) => m.MorePageModule),
      },
      {
        path: "news",
        loadChildren: () =>
          import("../news/news.module").then((np) => np.NewsPageModule),
      },
      {
        path: "news/article",
        loadChildren: () =>
          import("../news/news-article/news-article.module").then(
            (na) => na.NewsArticlePageModule
          ),
      },
      {
        path: "news/share",
        loadChildren: () =>
          import("../news/share-article/share-article.module").then(
            (sa) => sa.ShareArticlePageModule
          ),
      },
      {
        path: "news/share-story",
        loadChildren: () =>
          import("../news/share-story/share-story.module").then(
            (sa) => sa.ShareStoryPageModule
          ),
      },
      {
        path: "loads",
        loadChildren: () =>
          import("../loads/loads.module").then((l) => l.LoadsPageModule),
      },

      {
        path: "pin",
        loadChildren: () =>
          import("../pay/pin/pin.module").then((p) => p.PinPageModule),
      },
      {
        path: "forgot-pin",
        loadChildren: () =>
          import("../pay/forgot-pin/forgot-pin.module").then(
            (fp) => fp.ForgotPinPageModule
          ),
      },
      {
        path: "reset-pin",
        loadChildren: () =>
          import("../pay/reset-pin/reset-pin.module").then(
            (rp) => rp.ResetPinPageModule
          ),
      },
      {
        path: "pay",
        canActivate: [PayAuthGuardService],
        loadChildren: () =>
          import("../pay/pay.module").then((p) => p.PayPageModule),
      },
      {
        path: "pay/missing",
        loadChildren: () =>
          import("../pay/upcoming-paychecks/missing/missing.module").then(
            (pm) => pm.MissingPageModule
          ),
      },
      {
        path: "pay/in-process",
        loadChildren: () =>
          import("../pay/upcoming-paychecks/in-process/in-process.module").then(
            (ip) => ip.InProcessPageModule
          ),
      },
      {
        path: "pay/completed",
        loadChildren: () =>
          import("../pay/upcoming-paychecks/completed/completed.module").then(
            (cpm) => cpm.CompletedPageModule
          ),
      },
      {
        path: "pay/detail",
        canActivate: [PayAuthGuardService],
        loadChildren: () =>
          import(
            "../pay/past-paychecks/paycheck-detail/paycheck-detail.module"
          ).then((pd) => pd.PaycheckDetailPageModule),
      },
      {
        path: "pay/trip-detail",
        canActivate: [PayAuthGuardService],
        loadChildren: () =>
          import("../pay/past-paychecks/trip-detail/trip-detail.module").then(
            (td) => td.TripDetailPageModule
          ),
      },
      {
        path: "opportunities",
        loadChildren: () =>
          import("../opportunities/opportunities.module").then(
            (op) => op.OpportunitiesPageModule
          ),
      },
      {
        path: "safety",
        loadChildren: () =>
          import("../safety/safety.module").then((sa) => sa.SafetyPageModule),
      },
      {
        path: "safety/event-detail",
        loadChildren: () =>
          import(
            "../safety/components/safety-critical-events-details/safety-critical-events-details.module"
          ).then((ed) => ed.SafetyCriticalEventsDetailsPageModule),
      },
      {
        path: "reference-library",
        loadChildren: () =>
          import("../reference-library/reference-library.module").then(
            (sa) => sa.ReferenceLibraryPageModule
          ),
      },
      {
        path: "reference-library-details",
        loadChildren: () =>
          import(
            "../reference-library-details/reference-library-details.module"
          ).then((sa) => sa.ReferenceLibraryDetailsPageModule),
      },
      {
        path: "employee-resource",
        loadChildren: () =>
          import("../employee-resource/employee-resource.module").then(
            (er) => er.EmployeeResourcePageModule
          ),
      },
      {
        path: "safety/inspection-detail",
        loadChildren: () =>
          import(
            "../safety/components/safety-inspections-details/safety-inspections-details.module"
          ).then((sid) => sid.SafetyInspectionsDetailsPageModule),
      },
      {
        path: "messages",
        loadChildren: () =>
          import("../messages/messages.module").then(
            (m) => m.MessagesPageModule
          ),
      },
      {
        path: "broadcast-message-details/:threadId",
        loadChildren: () =>
          import(
            "../broadcast-message-details/broadcast-message-details.module"
          ).then((m) => m.BroadcastMessageDetailsPageModule),
      },
      {
        path: "personal-message-details/:threadId",
        loadChildren: () =>
          import(
            "../personal-message-details/personal-message-details.module"
          ).then((m) => m.PersonalMessageDetailsPageModule),
      },
      {
        path: "compose-message",
        loadChildren: () =>
          import("../compose-message/compose-message.module").then(
            (m) => m.ComposeMessagePageModule
          ),
      },
      {
        path: "reply-to-message/:threadId",
        loadChildren: () =>
          import("../reply-to-message/reply-to-message.module").then(
            (m) => m.ReplyToMessagePageModule
          ),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("../settings/settings.module").then(
            (m) => m.SettingsPageModule
          ),
      },
      {
        path: "driver-profile",
        loadChildren: () =>
          import("../profile-v2/profile-v2.module").then(
            (m) => m.Profilev2PageModule
          ),
      },
      {
        path: "admin/messages",
        canActivate: [QaAuthService],
        loadChildren: () =>
          import("../admin/messages/messages.module").then(
            (m) => m.MessagesPageModule
          ),
      },
      {
        path: "driver-referral",
        loadChildren: () =>
          import("../driver-referral/driver-referral.module").then(
            (cp) => cp.DriverReferralModule
          ),
      },
      {
        path: "location-feedback",
        loadChildren: () =>
          import("../location-feedback/location-feedback.module").then(
            (m) => m.LocationFeedbackPageModule
          ),
      },

      {
        path: "trip-steps-map",
        loadChildren: () =>
          import("../trip-steps-map/trip-steps-map.module").then(
            (m) => m.TripStepsMapPageModule
          ),
      },
      {
        path: "blue-coins",
        canActivate: [QaAuthService],
        loadChildren: () =>
          import("../blue-coins/blue-coins.module").then(
            (m) => m.BlueCoinsPageModule
          ),
      },
      {
        path: "leaderboard",
        canActivate: [QaAuthService],
        loadChildren: () =>
          import("../leaderboard/leaderboard.module").then(
            (m) => m.LeaderboardPageModule
          ),
      },
      {
        path: "benefits",
        loadChildren: () =>
          import("../benefits/benefits.module").then(
            (m) => m.BenefitsPageModule
          ),
      },
      {
        path: "benefits-contacts-details",
        loadChildren: () =>
          import(
            "../benefits/components/benefits-contacts-details/benefits-contacts-details.module"
          ).then((m) => m.BenefitsContactsDetailsPageModule),
      },
      {
        path: "cass-chat",
        loadChildren: () =>
          import("../cass-chat/cass-chat.module").then(
            (m) => m.CassChatPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    SharedComponentsModule,
    FontAwesomeModule,
  ],
  declarations: [LandingPage],
})
export class LandingPageModule {}
