// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {
  ResponseType,
  StorageType,
} from "@pingidentity-developers-experience/ping-oidc-client-sdk";
export const environment = {
  production: false,
  isEdgeConnectDevice: false,
  baseApiUrl: "https://dwp-cde.werner.com/api/v1",
  baseApiV2Url: "https://dwp-api-cde.werner.com",
  //baseApiUrl: 'https://cde-app-cus-dwp-api-01.azurewebsites.net',
  // baseApiUrl: 'https://localhost:5001',
  // baseApiUrl: 'https://localhost:44341',
  // baseApiUrl: 'https://dwp-cde.werner.com/api/v1',
  dialogflow: {
    angularBot: "5431e6c3f52e4b14a3410958deb46ae6",
  },
  trimbleMapKey: "B6ED1A19F456AE45BD0A76E3813CC249",
  google: {
    analyticsTrackerId: "G-GJQQCX88RM",
  },
  appInsights: {
    instrumentationKey: "84f710db-b147-4cc7-833e-cd80033ecf01",
  },
  appName: "DriveWerner",
  defaultRecipient: "WRNRTEST",
  streamLineRecipient: "WRNRMDT",

  oidc: {
    clientId: "0oask1i7c8FJnXgtO0h7",
    issuer: "https://ssoext-preview.werner.com/oauth2/default",
    redirectUri: window.location.origin + "/implicit/callback",
    logoutRedirectUri: window.location.origin + "/implicit/logout",
    scopes: ["openid", "profile", "offline_access"],
    responseType: "id_token" as const,
    defaultUri: "com.werner.ssoext-preview:/",
  },
  launchDarkly: {
    clientId: "6459347ed51645124e92ddcd",
  },

  pingClientOptions: {
    client_id: "d90619fb-83ef-4e17-a6eb-1e5f9616cff2",
    //redirect_uri: "com.werner.ssoext://implicit/callback",
    redirect_uri: window.location.origin + "/implicit/callback",
    response_type: ResponseType.AuthorizationCode,
    //scope: "openid profile",
    scope: "openid profile werner revoke",
    usePkce: true,
    storageType: StorageType.Session,
    defaultUri: window.location.origin,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.