import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable, lastValueFrom, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthService } from "../auth/auth.service"; // Your auth service that provides the refreshToken method
import { from } from "rxjs";
import { PingAuthService } from "../auth/ping-auth.service";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    // Ensure that pingPlatform is initialized and has 'is' method
    if (AuthService.pingPlatform && AuthService.pingPlatform.is) {
    if(AuthService.pingPlatform.is("desktop") || AuthService.pingPlatform.is("mobileweb")|| AuthService.pingPlatform.is("android"))
      {
        if (AuthService._isAuthenticated.value) {
          var token = await PingAuthService.getValidToken();
      } 
     }else if( AuthService.pingPlatform.is("ios")) {
        if (AuthService._isAuthenticated.value) {
          var token = await this.authService.getValidToken();
      }
    }
    if (token) {
      console.log("TokenInterceptor token", token);
      const authReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return await next.handle(authReq).toPromise();
    } 
    }
  }

  //   intercept(
  //     request: HttpRequest<any>,
  //     next: HttpHandler
  //   ): Observable<HttpEvent<any>> {
  //     if (this.authService._isAuthenticated.value) {
  //       console.log("TokenInterceptor");
  //       return from(this.authService.getValidToken()).pipe(
  //         switchMap(async (token) => {
  //           console.log("TokenInterceptor token", token);
  //           const authReq = request.clone({
  //             setHeaders: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           });
  //           return await next.handle(authReq).toPromise();
  //         })
  //       );
  //     } else {
  //       return next.handle(request);
  //     }
  //   }
}