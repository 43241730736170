import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { AuthService } from 'src/app/auth/auth.service';
import { RequestService } from 'src/app/Swagger-Gen-V2';
@Injectable()
export class TelemetryUtility {
    constructor(
        private requestService:RequestService,
        private authService : AuthService
        ) {}

    async recordTelemetry(eventName:string){
        const identityKey   = await this.authService.getUserIdentitityKey();
        const deviceInfo = await Device.getId();
        const deviceId   = (deviceInfo)?deviceInfo.uuid.toString():'';
        const payload = {
            "eventName":eventName,
            "identityKey":identityKey,
            "deviceId":deviceId
        };
        console.log("identityKey",identityKey );
        console.log("eventName", eventName);
        console.log("deviceId", deviceId);
        console.log("Payload", payload);
        
        return this.requestService.telemetryPost(payload).toPromise();
    }
}
