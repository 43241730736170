import { registerPlugin, Plugin } from "@capacitor/core";

interface NativePluginInterface extends Plugin {
  scanPaperWork: (options: {
    recipient: any;
    identityId: any;
    appName: any;
    firstName: any;
    lastName: any;
    docuPath: any;
    isEdgeConnectDevice: boolean;
  }) => Promise<any>;
  viewPaperWork: (options: {
    confirmationCode: any;
  }) => Promise<any>;
  generateTiffEnvelopeImage: (options: {
    imageContent: any;
  }) => Promise<any>;
  openWebviewWorkdayUrl: (options: {
    workdayUrl: any;
  }) => Promise<any>;
  InitSso: () => Promise<any>;
  PingSSOLogOut: () => Promise<any>;
  RefreshToken: () => Promise<any>;
}
const nativePlugin = registerPlugin<NativePluginInterface>("nativePlugin");
export default nativePlugin;
