import { Injectable, OnDestroy } from '@angular/core';
import * as TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { RequestService, PointOfInterestResponse } from 'src/app/Swagger-Gen-V2';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MapService implements OnDestroy {
  map: TrimbleMaps.Map;
  private transportationStyle = TrimbleMaps.Common.Style.TRANSPORTATION;
  private satelliteStyle = TrimbleMaps.Common.Style.SATELLITE;
  private apiKey = environment.trimbleMapKey;
  private mapCenter = {
    lon: -96.141243,
    lat: 41.158642,
    zoom: 10,
  };
  centerLngLat: TrimbleMaps.LngLatLike = {
    lat: this.mapCenter.lat,
    lng: this.mapCenter.lon    
  };
  subscriptions$ = new SubSink();

  constructor(
    private geolocation: Geolocation,
    private apiService: RequestService
  ) {}

  toggleStyle() {
    this.map.getStyle().name == 'Transportation' ? this.map.setStyle(this.satelliteStyle) : this.map.setStyle(this.transportationStyle)
  }

  async initMap(options?: any): Promise<TrimbleMaps.Map> {
    this.setApiKey(this.apiKey);
    let mapOptions = options;

    mapOptions = {
      container: options.container,
      zoom: options.zoom || this.mapCenter.zoom,
      center:  options.center || [this.mapCenter.lon, this.mapCenter.lat],
      style: this.transportationStyle,
      attributionControl: false,
      hash: false
    };
    if(this.map)
    {
      this.map.remove();
    }
    this.map = new TrimbleMaps.Map(mapOptions);

    return this.map;
  }

  locateUser(zoom: number = 15, animateCamera: boolean = true): Promise<any> {
    // Use Ionic Geolocation to get user location
    return this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        const lat = resp.coords.latitude;
        const lng = resp.coords.longitude;

        // Convert lat/long response to coordinates with Trimble Maps default projection
        const coordinates = TrimbleMaps.LngLat.convert([lng, lat]);

        // Zoom to coordinates
        if (animateCamera) {
          this.defaultFlyTo(coordinates, zoom);
        }

        // Return the coordinates so the marker may be placed
        return coordinates;
      })
      .catch((error) => {
        console.log('Error getting location', error);
      });
  }

  async getLocalPoiWith(identityKey: string): Promise<PointOfInterestResponse> {
    let mapCenter = this.map.getCenter()
    return this.getReverseGeoCoder(mapCenter.lat, mapCenter.lng).then((address: any) =>
      this.getPOI(identityKey, address.Zip));
      }

  getPOI(identityKey: string, zipcode: string) {
    return new Promise((resolve, reject) => {
      if (zipcode) {
      this.subscriptions$.sink =
      this.apiService.poisGet(
        zipcode,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true
      ).subscribe(async response => {
        response.proximityZipCode = zipcode;
        resolve(response);
      });
    } else {
      resolve(null);
    }
    });
  }

  getReverseGeoCoder(lat: number, lng: number) {
    return new Promise((resolve, reject) => {
      TrimbleMaps.Geocoder.reverseGeocode({
        lonLat: new TrimbleMaps.LngLat(lng, lat),
        region: TrimbleMaps.Common.Region.NA,
        success: function (response) {
          const address =
            response && response.length > 0 && response[0].Address ? response[0].Address : null;
          resolve(address);
        },
        failure: function (response) {
          console.log(response);
          resolve(null);
        }
      });
    });
  }

  private flyTo(options) {
    if (this.map !== undefined) {
      this.map.flyTo(options);
    }
  }

  defaultFlyTo(lngLat: TrimbleMaps.LngLat, zoom: number = 9) {
    this.flyTo({
      center: lngLat,
      zoom: zoom,
      speed: 2,
      curve: 1,
      easing(v) {
        // EaseInOutExponential Formula
        //  v = 0..1    // Current value
        const c = 1; // Easing value change
        const d = 1; // Easing duration

        v /= d / 2;
        if (v < 1) { return (c / 2) * Math.pow(2, 10 * (v - 1)); }
        v--;
        return (c / 2) * (-Math.pow(2, -10 * v) + 2);
      }
    });
  }

  // Map Center
  private setMapCenter(
    lngLat: { lng: number; lat: number } | [number, number],
    panTo?: boolean
  ) {
    if (panTo) {
      this.map.panTo(lngLat);
    } else {
      this.map.setCenter(lngLat);
    }
  }

  getMapCenter() {
    return this.map.getCenter();
  }

  setApiKey(apiKey: string) {
    TrimbleMaps.setAPIKey(apiKey);
  }

  setStyle(style: string) {
    this.map.setStyle(style);
  }

  getStyle() {
    return this.map.getStyle().name;
  }

  getRegion() {
    return this.map.getRegion();
  }

  on(event: string, listener) {
    this.map.on(event, listener);
  }

  off(event: string, listener) {
    this.map.off(event, listener);
  }

  getMap() {
    return this.map;
  }

  addLayer(layer) {
    this.map.addLayer(layer);
  }

  removeLayer(layerId) {
    this.map.removeLayer(layerId);
  }

  removeSource(id: string) {
    this.map.removeSource(id);
  }

  removeImage(id: string) {
    this.map.removeImage(id);
  }

  hasImage(id: string): boolean {
    return this.map.hasImage(id);
  }

  toggleLayerVis(layerName) {
    const current = this.map.getLayoutProperty(layerName, 'visibility');
    const visibility = current === 'visible' ? 'none' : 'visible';
    this.map.setLayoutProperty(layerName, 'visibility', visibility);
    return visibility === 'visible' ? true : false;
  }

  getStyleLayers() {
    return this.map.getStyle().layers;
  }

  setPOIVisibility(isVisible: boolean) {
    this.map.setPOIVisibility(isVisible);
  }

  ngOnDestroy() {
    if(this.map)
    {
      this.map.remove();
    }
    this.subscriptions$.unsubscribe();
  }
}
