import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { IUserInfo } from "./user-info.model";
import { DispatchService } from "../shared/services/dispatchers/dispatch.service";
import { StorageConstants } from "../shared/utilities/storage-constants";
import { StorageKeyValue } from "../shared/models/storage-key-value";
import { Platform } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { SubSink } from "subsink";
import { Preferences } from "@capacitor/preferences";
import { AdministrationFeatureFlags } from "../Swagger-Gen-V2/model/administrationFeatureFlags";
import nativePlugin from "src/plugins/nativePlugin";
import * as UserDataInfo from "src/app/modules/profile-v2/state/user-profile.reducer";
import { JwtHelperService } from "@auth0/angular-jwt";
import {
  ClientOptions,
  OidcClient,
  ResponseType,
  StorageType,
  TokenResponse,
} from "@pingidentity-developers-experience/ping-oidc-client-sdk";
import { Router, ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { SplashScreen } from "@capacitor/splash-screen";
import { PingAuthService } from "./ping-auth.service";
import PingPlugin from "src/plugins/PingPlugin";
import * as fromRoot from "./../state/root.reducer";
import * as RootActions from "src/app/state/root.actions";
import { AppReloadComponent } from "src/app/shared/components/app-reload.component/app-reload.component";

declare let pendo;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  static userInfo: IUserInfo;
  widget;
  isPendoEnabled: boolean;
  subscriptions$ = new SubSink();
  featureFlags: AdministrationFeatureFlags;
  config;
  oidcClient: Promise<OidcClient>;
  static _isAuthenticated: BehaviorSubject<boolean>;
  static token?: TokenResponse;
  static pingOidcClient: Promise<OidcClient>;
  static pingPlatform: Platform;
  static isRefreshingToken: boolean = false;
  static refreshTokenPromise: Promise<TokenResponse | null> | null = null;
  static pingRouter: Router;
  static rootStoreState: any;
  static helper = new JwtHelperService();

  constructor(
    private dispatchService: DispatchService,
    private platform: Platform,
    private router: Router,
    private rootStore: Store<fromRoot.State>,
    private userStore: Store<UserDataInfo.State>,
    private route: ActivatedRoute,
    private reload: AppReloadComponent
  ) {
    AuthService.rootStoreState = this.rootStore;
    const savedAuthState = localStorage.getItem("isAuthenticated");
    const initialAuthState = savedAuthState ? JSON.parse(savedAuthState) : null;
    AuthService._isAuthenticated = new BehaviorSubject<boolean>(
      initialAuthState
    );
    if (platform.is("desktop") || platform.is("mobileweb")) {
      if (!this.oidcClient || this.oidcClient === undefined) {
        console.log("Auth Service constructor called");
        this.oidcClient = PingAuthService.initializePingAuth();
        AuthService.pingOidcClient = this.oidcClient;
      }
      AuthService.pingOidcClient = this.oidcClient;
    } else if (platform.is("android")) {
      console.log("Auth Service Android constructor called");
      token: {
        access_token: "";
      }
      AuthService.pingRouter = this.router;
    } else if (platform.is("ios")) {
      this.oidcClient = JSON.parse(localStorage.getItem("oidcClient"));
      console.log("<<<<<<<<<<<<From Auth service Component >>>>>>>>>>>");
      //const initConfig = nativePlugin.InitConfig();
      this.PingSSOLogin();
    }
    AuthService.pingPlatform = this.platform;
  }

  async PingSSOLogin() {
    this.rootStore.dispatch(new RootActions.Logout());
    console.log("PingSSO Strat");
    let pingResponse = await nativePlugin.InitSso();
    console.log("Ping Response", pingResponse)
    //if(pingResponse)
    //{
    await this.SetUserInformation(pingResponse);
    await this.LaunchApp();
    console.log("PingSSO End");

    //}
  }

  async SetUserInformation(pingResponse) {
    console.log("AccessToken FrontEnd", pingResponse.AccessToken);
    console.log("UserClaim FrontEnd", pingResponse.UserClaim);
    var accessToken = pingResponse.AccessToken;
    var userClaim = JSON.parse(pingResponse.UserClaim);

    this.oidcClient = accessToken;
    const tokenModal = <TokenResponse>{};
    tokenModal.access_token = accessToken;
    await this.settoken(tokenModal);

    const userInfo: IUserInfo = {
      identityKey: userClaim.identityKey,
      user_name:
        userClaim.firstName +
        " " +
        userClaim.middle_name +
        " " +
        userClaim.lastName,
      name: userClaim.given_name,
      preferred_username: userClaim.preferred_username,
      given_name: userClaim.given_name,
    };
    AuthService.userInfo = userInfo;
    Preferences.set({
      key: StorageConstants.USER_IDENTITY_KEY,
      value: userClaim.identityKey,
    });
    console.log("userdataKey---->?>>>>>", AuthService.userInfo.identityKey);
    console.log("user_name---->?>>>>>", AuthService.userInfo.user_name);
    console.log("name---->?>>>>>", AuthService.userInfo.name);
    console.log(
      "preferred_username---->?>>>>>",
      AuthService.userInfo.preferred_username
    );
    console.log("given_name---->?>>>>>", AuthService.userInfo.given_name);
  }

  async LaunchApp() {
    await AuthService.setAuthenticated(true);
    await this.setUserProfile();
    this.reload.reloadComponent(false, "HamburgerMenuComponent");
    //this.handleAuthCallback();
    SplashScreen.hide();
  }

  async settoken(token: TokenResponse) {
    await this.tokenAvailable(token);
    console.log("settoken", token.access_token);
  }

  async tokenAvailable(token: TokenResponse) {
    AuthService.token = token;
    await AuthService.setAuthenticated(true);
    console.log("tokenAvailable", token.access_token);
    try {
      console.log("Auth Service tokenAvailable called Success");
      //await this.getUserInfoFromToken();
    } catch {
      console.log("Auth Service tokenAvailable called Fail");
      await (await this.oidcClient)
        ?.refreshToken()
        .then((token: TokenResponse) => {
          AuthService.token = token;
        });
      await this.getUserInfoFromToken();
    }
  }

  get isAuthenticated() {
    return AuthService._isAuthenticated.asObservable();
  }

  static async setAuthenticated(isAuthenticated: boolean) {
    this._isAuthenticated.next(isAuthenticated);
    localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
  }

  async getWidget() {
    if (
      AuthService.pingPlatform.is("desktop") ||
      AuthService.pingPlatform.is("mobileweb")
    ) {
      console.log("Auth Service getWidget called");
      (await this.oidcClient).authorize();
    } else if (AuthService.pingPlatform.is("android")) {
      AuthService.token = {
        access_token: "",
        token_type: "",
        expires_in: 0,
        refresh_token: "",
        id_token: "",
        scope: "",
      };
      await PingAuthService.initializeAndroidPingAuth().then(async (data) => {
        AuthService.setAuthenticated(true);
        await this.setUserProfile();
        AuthService.pingRouter.navigate([""]);
      });
    } else if (AuthService.pingPlatform.is("ios")) {
      return this.widget;
    }
  }

  async getUserInfo() {
    console.log("Auth Service getUserInfo called");
    if (AuthService.pingPlatform.is("ios")) {
      if (AuthService.userInfo) return AuthService.userInfo;
    } else {
      if (AuthService.userInfo) return AuthService.userInfo;
      if (
        AuthService._isAuthenticated.value === false ||
        AuthService.userInfo === undefined ||
        AuthService.userInfo === null
      ) {
        await PingAuthService.getValidToken();
      }

      const userClaim = await PingAuthService.getUserInfoFromToken();
      const userInfo: IUserInfo = {
        identityKey: userClaim.identityKey,
        user_name: userClaim.user_name,
        name: userClaim.name,
        preferred_username: userClaim.preferred_username,
        given_name: userClaim.given_name,
      };
      AuthService.userInfo = userInfo;

      const identityKeyCheck = await Preferences.get({
        key: StorageConstants.USER_IDENTITY_KEY,
      });

      // If the logged in user is different, clear storage
      if (
        identityKeyCheck.value !== null &&
        identityKeyCheck.value !== AuthService.userInfo.identityKey
      ) {
        AuthService.clearStorage();
      }
      this.featureFlags = await this.dispatchService.getFeatureFlags();

      this.isPendoEnabled =
        this.featureFlags &&
        this.featureFlags.flags &&
        this.featureFlags.flags.PendoEnabled !== undefined
          ? this.featureFlags.flags.PendoEnabled
          : null;
      if (this.isPendoEnabled) {
        this.initializePendo();
      }

      await Preferences.set({
        key: StorageConstants.USER_IDENTITY_KEY,
        value: AuthService.userInfo.identityKey,
      });

      const identityKey = await Preferences.get({
        key: StorageConstants.USER_IDENTITY_KEY,
      });
      console.log("Auth Service identityKey", identityKey);
      console.log("Auth Service userInfo", userInfo);
      return userInfo;
    }
  }

  private async getUserInfoFromToken() {
    return AuthService.userInfo;
  }

  async handleAuthCallback() {
    await this.getValidToken();
    this.router.navigate([""]);
  }

  async getValidToken(): Promise<string> {
    console.log("");
    
    if (this.oidcClient === undefined) {
      await this.PingSSOLogin();
    }
    // localStorage.setItem("oidcClient", JSON.stringify(await this.oidcClient));
    console.log("Auth Service getValidToken called");

    var token = this.getAccessToken();
    if (token) {
      var isTokenExpired = AuthService.helper.isTokenExpired(token);
      if (isTokenExpired) {
        let pingResponse = await nativePlugin.RefreshToken();
        if (pingResponse) {
          this.SetUserInformation(pingResponse);
        }
      }
    }
    return AuthService.token?.access_token;
  }

  async refreshToken(): Promise<TokenResponse | null> {
    if (AuthService.isRefreshingToken) {
      return AuthService.refreshTokenPromise;
    }

    AuthService.isRefreshingToken = true;
    AuthService.refreshTokenPromise = (async () => {
      try {
        const refreshedToken = await (
          await this.oidcClient
        ).refreshToken();
        AuthService.token = refreshedToken || null;
        return AuthService.token;
      } catch (error) {
        console.error("Error refreshing token", error);
        return null;
      } finally {
        AuthService.isRefreshingToken = false;
        AuthService.refreshTokenPromise = null;
      }
    })();

    return AuthService.refreshTokenPromise;
  }

  getAccessToken() {
    return AuthService.token?.access_token;
  }

  private initializePendo(): void {
    pendo.initialize({
      visitor: {
        id: AuthService.userInfo.preferred_username,
        identityKey: AuthService.userInfo.identityKey,
        givenName: AuthService.userInfo.given_name,
        user_name: AuthService.userInfo.user_name,
        name: AuthService.userInfo.name,
      },
    });
  }
  public async setUserProfile() {
    if (AuthService._isAuthenticated.value === true) {
      await this.getUserInfo();
      this.dispatchService.homeLoadDispatch(AuthService.userInfo.identityKey);
    } else if (AuthService.pingPlatform.is("ios")) {
      await this.getUserInfo();
      this.dispatchService.homeLoadDispatch(AuthService.userInfo.identityKey);
    }
  }

  static async extractStorageKeysNotToBeDeleted() {
    // Keys that should not be deleted even after the user logs off.
    // E.g. Customized cards
    var keysNotToBeDeleted = new Array<StorageKeyValue>();

    let keys = (await Preferences.keys()).keys;
    if (keys && keys.length > 0) {
      keys.forEach(async (key) => {
        if (key.startsWith(StorageConstants.CARDS_KEY + "_")) {
          let item = await Preferences.get({ key: key });
          keysNotToBeDeleted.push({
            key: key,
            value: item.value,
          });
        }
      });
    }
    return keysNotToBeDeleted;
  }

  static async addKeysToStorage(keyValues: Array<StorageKeyValue>) {
    // Add the keys back to storage
    if (keyValues.length > 0) {
      for (let i = 0; i < keyValues.length; i++) {
        Preferences.set({ key: keyValues[i].key, value: keyValues[i].value });
      }
    }
  }

  static async clearStorage() {
    // Extract keys that are not to be deleted
    const keysNotToBeDeleted = await this.extractStorageKeysNotToBeDeleted();
    // Clear the storage
    if (
      AuthService.pingPlatform.is("android") ||
      AuthService.pingPlatform.is("ios")
    ) {
      await Preferences.clear();
    }
    localStorage.clear();
    // Add keys back to the storage
    await this.addKeysToStorage(keysNotToBeDeleted);
  }

  static async signOut(): Promise<void> {
    try {
      await this.clearStorage();
      console.log("Auth Service signOut called");

      if (AuthService.pingPlatform.is("android")) {
        console.log("Auth Service signOut button clicked - Android");
        await PingPlugin.logoutUser({})
          .then(() => {
            console.log("PingPlugin.logoutUser: Logout successful.");
          })
          .catch((err) => {
            console.error("PingPlugin.logoutUser: Logout failed", err);
            throw err; // Handle logout failure
          });

        AuthService.setAuthenticated(false);
        console.log("Auth Service setAuthenticated(false) called.");
        AuthService.rootStoreState.dispatch(new RootActions.Logout());
        console.log("Dispatched RootActions.Logout.");
      } else if (
        AuthService.pingPlatform.is("desktop") ||
        AuthService.pingPlatform.is("mobileweb")
      ) {
        if (AuthService._isAuthenticated.value === true) {
          const client = await AuthService.pingOidcClient;
          await client.revokeToken();
          console.log("Token revoked successfully.");
          await AuthService.setAuthenticated(false);
          await client.endSession(window.location.origin);
          console.log("OIDC session ended.");
        } else {
          AuthService.prototype.router.navigate(["/login"]);
        }
      } else if (AuthService.pingPlatform.is("ios")) {
        console.log("<<<<<<<<<<<<   sign out called >>>>>>>>>>>");
        console.log(
          "<<<<<<<<<<<< sign out -Ping log in called from sign out >>>>>>>>>>>"
        );

        let pingLogOutResponse = await nativePlugin.PingSSOLogOut();
        if (pingLogOutResponse) {
          console.log(
            "LogOut --- PingSSO LogOut Success",
            pingLogOutResponse.message
          );
          this.setAuthenticated(false);
        }
      }
    } catch (error) {
      console.error("Error during signOut:", error);
    }
  }

  onDevice(): boolean {
    return this.platform.is("mobile") && !this.platform.is("mobileweb");
  }

  async getUserIdentitityKey() {
    const identityKey = await Preferences.get({
      key: StorageConstants.USER_IDENTITY_KEY,
    });
    return identityKey && identityKey.value ? identityKey.value : "";
  }

  // async handleAuthCallback() {
  //   console.log("Auth Service handleAuthCallback called");
  //   await PingAuthService.getValidToken(this);
  //   console.log(
  //     "Auth Service handleAuthCallback called - token value is : " +
  //       this.token.access_token
  //   );
  //   await this.setUserProfile();
  //   this.router.navigate([""]);
  // }

  // async getValidToken(): Promise<string> {
  //   var oidcClient = await PingAuthService.oidcClient;
  //   if (await oidcClient.hasToken()) {
  //     var token: TokenResponse;
  //     token = await oidcClient.getToken();
  //     try {
  //       console.log("this.token", token);
  //       await this.getUserInfoFromToken();
  //       //this.userInfo = await oidcClient.fetchUserInfo();
  //       await this.setAuthenticated(true);
  //     } catch (error) {
  //       token = (await (await this.oidcClient).refreshToken()) as TokenResponse;
  //       console.log("this.token", token);
  //       await this.getUserInfoFromToken();
  //       await this.setAuthenticated(false);
  //     }
  //     this.token = token;
  //   } else {
  //     await this.setAuthenticated(false);
  //     this.router.navigate(["/login"]);
  //   }
  //   return this.token.access_token;
  // }

  // async tokenAvailable(token: TokenResponse) {
  //   this.token = token;
  //   await this.setAuthenticated(true);

  //   try {
  //     console.log("Auth Service tokenAvailable called Success");
  //     await this.getUserInfoFromToken();
  //   } catch {
  //     console.log("Auth Service tokenAvailable called Fail");
  //     await (await this.oidcClient)
  //       ?.refreshToken()
  //       .then((token: TokenResponse) => {
  //         this.token = token;
  //       });
  //     await this.getUserInfoFromToken();
  //   }
  // }

  // async getUserInfoFromToken() {
  //   await (await this.oidcClient)
  //     ?.fetchUserInfo()
  //     .then((userInfo: IUserInfo) => {
  //       this.userInfo = userInfo;
  //     });
  //   return this.userInfo;
  // }
}
