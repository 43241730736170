import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { CameraService } from 'src/app/shared/services/camera/camera.service';
import { IUserInfo } from '../../../../auth/user-info.model';
import { AuthService } from '../../../../auth/auth.service';
import { EdgeCard } from 'src/app/shared/models/edge-card';
import { Subscription } from 'rxjs';
import { ProfileInfoService } from 'src/app/modules/profile-v2/service/profileInfo.service';
import { Profile } from 'src/app/Swagger-Gen-V2';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';

@Component({
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput;
  @Input() profileCard: any;
  @Input() card: EdgeCard;
  @Output() resize = new EventEmitter();
  @Output() change = new EventEmitter();
  @Output() remove = new EventEmitter();
  public displayName = 'Driver';
  public profileUrl = '';
  public defaultProfileUrl = '../../../../../assets/Images/werner2_photo.png';
  greeting: string;
  subscription: Array<Subscription> = [];

  userInfo: IUserInfo;
  identityKey = '';
  userPictureUrl = null;
  coverPictureUrl = '';
  counter = 0;
  profile : Profile;


  constructor(
    private actionSheetController: ActionSheetController, private authService: AuthService,
    private cameraService: CameraService,
    private platform: Platform,
    private dispatchService: DispatchService,
    private profileInfoService : ProfileInfoService
  ) {
  }
  async ngOnInit(): Promise<void> {
    // load photo
    await this.cameraService.loadSaved();
    this.identityKey = await this.authService.getUserIdentitityKey();
    await this.getUserInfo();
    this.setGreetingMessage();
  }

  setGreetingMessage() {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    // we are dealing with Date() in 24 hour format.
    if (currentHour < 12) {
      this.greeting = 'Good Morning';
    } else if (currentHour < 18) {
      this.greeting = 'Good Afternoon';
    } else {
      this.greeting = 'Good Evening';
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.forEach(s => {
        s.unsubscribe();
      });
    }
  }

  public async getUserInfo(): Promise<void> {
    this.userInfo = await this.authService.getUserInfo();
    this.identityKey = await this.authService.getUserIdentitityKey();
    this.profile = await this.profileInfoService.getUserProfileInfo();
    if(this.profile && this.profile.user && this.profile.user.nickname) {
      this.displayName = this.profile.user.nickname;
    } else {
      this.displayName = this.userInfo.given_name;
    }
    this.profileUrl = await this.dispatchService.getProfileImage();
    // Set the profile and cover url somewhere here. Need to call profile end point to get profile and cover
    this.coverPictureUrl = this.cameraService.photo ? this.cameraService.photo.webviewPath : '';
    if (this.coverPictureUrl === undefined
      || this.coverPictureUrl === '') {
      this.coverPictureUrl = this.defaultProfileUrl;
    }
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Cover Photo',
      buttons: [{
        text: 'Upload Photo',
        icon: 'image',
        handler: () => {
          this.cameraService.imageFromPhotoGallery().then((async () => {
            this.coverPictureUrl = this.cameraService.photo.webviewPath;
          }));
        }
      },
      {
        text: 'Take Photo',
        icon: 'camera',
        handler: () => {
          this.cameraService.imageFromCamera().then((async () => {
            this.coverPictureUrl = this.cameraService.photo.webviewPath;
          }));
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {

        }
      }]
    });
    await actionSheet.present();
  }

  tapCard() {
    if (!(this.platform.is("desktop") || this.platform.is("mobileweb"))) {
      this.card.showSizePositionControl || this.card.preview ? !this.card.showOverlay : this.presentActionSheet();
    } else {
      this.fileInput.nativeElement.click()
    }
  }

  selectBannerImage(input) {
    this.cameraService.getImageUrl(input).then((async () => {
      await this.cameraService.loadSaved();
      this.coverPictureUrl = this.cameraService.photo.webviewPath;
    }));
  }

  resizeCard() {
    this.resize.emit(this.card);
  }

  changeCard() {
    this.change.emit(this.card);
  }

  removeCard() {
    this.remove.emit(this.card);
  }
}
