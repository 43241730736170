import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { PingAuthService } from "src/app/auth/ping-auth.service";

@Component({
  selector: "app-ping-callback",
  templateUrl: "./ping-callback.component.html",
  styleUrls: ["./ping-callback.component.scss"],
})
export class PingCallbackComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    await PingAuthService.handleAuthCallback(this.authService);
    this.router.navigate(["/"]);
  }
}