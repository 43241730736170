import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { IUserInfo } from 'src/app/auth/user-info.model';
import { WernerWelcomeExperience } from 'src/app/Core/app-values';
import { EdgeCardService } from 'src/app/shared/services/edge-card.service';
import { StorageConstants } from 'src/app/shared/utilities/storage-constants';
import { SubSink } from 'subsink';

import { AdminMenu } from '../../../shared/models/admin-menu';
import { OrientationDetails } from 'src/app/Swagger-Gen-V2/model/orientationDetails';
import { environment } from 'src/environments/environment';
import { Preferences } from '@capacitor/preferences';

import * as Menu from '../json/menu.json';
import { AdministrationFeatureFlags } from 'src/app/Swagger-Gen-V2/model/administrationFeatureFlags';
import { DispatchService } from 'src/app/shared/services/dispatchers/dispatch.service';
import { RequestService } from 'src/app/Swagger-Gen-V2';
// Help link for why this service was created
// https://stackoverflow.com/questions/60197785/how-to-fix-member-event-from-ionic-angular-error-in-ionic-5
@Injectable({
  providedIn: 'root'
})
export class MenuItemsService implements OnDestroy {

  showWorkdayELearning: boolean = false;
  showGamification: boolean = false;
  isWebApp: boolean = false;
  featureFlags: AdministrationFeatureFlags;

  constructor(
    private authService: AuthService,
    private requestService:RequestService,
    private cardService: EdgeCardService,
    router: Router,
    platform: Platform,
    private dispatchService : DispatchService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.routeChanged();
      }
    });


    this.isWebApp = (platform.is("desktop") || platform.is("mobileweb"));
    this.getUserInfo();
    this.fetchAppFeaturesConfiguration();
    // set driver poral URL based on ENV
    let driverPortalUrl = (environment.production === true) ? "https://drivers.werner.com/" : "http://qa-driverportal.wernerds.net/";
    let driverPortalIndex = Menu.default.menuItems.map(function (x) { return x.label; }).indexOf("Driver Portal");
    Menu.default.menuItems[driverPortalIndex].url = driverPortalUrl;

    for (var index in Menu.default.hideMenuItems) {
      const device = Menu.default.hideMenuItems[index].device;
      const env = Menu.default.hideMenuItems[index].env;
      const name = Menu.default.hideMenuItems[index].name;
      // for webapp device
      if (device == "web") {
        this.showOrHideMenuItems(name, this.isWebApp);
      }
      // for mobile app device
      if (device == "mobile") {
        this.showOrHideMenuItems(name, !this.isWebApp);
      }      

      // for qa env
      if (env == "qa") {
        this.showOrHideMenuItems(name, !environment.production);
      }            
    }

   }

  orientationDetails: OrientationDetails = null;
  subscriptions$ = new SubSink();
  userInfo: IUserInfo;
  identityKey = '';
  menuItems = Menu.default.menuItems;

  adminSubMenuItems = [
    {
      iconType: 'fontawesome',
      label: 'Messages',
      icon: 'mailbox',
      url: '/admin/messages',
      cls: 'messages-item',
      visible: true
    }
  ];
  private subject = new Subject<any>();
  public readonly adminMenuItemsSubject = new Subject<AdminMenu[]>();

  getAdminSubMenuItems () : Observable<any>{
    return this.adminMenuItemsSubject.asObservable();
  }

  setAdminSubMenuItems() {
    this.adminMenuItemsSubject.next(this.adminSubMenuItems.filter(item => item.visible === true));
  }

  public async getUserInfo(): Promise<void> {
    console.log("Before getting Identity menu-Items-servie",this.identityKey);
    this.identityKey = await this.authService.getUserIdentitityKey();
    console.log("After getting Identity menu-Items-service",this.identityKey);
  }

  // Returns menu items that should be displayed to the user
  getMenuItems(): Observable<any> {
    return this.subject.asObservable();
  }

  // Hides menu item with specified label
  hideMenuItemByLabel(label: string) {
    const menuIndex = this.menuItems.findIndex(menuItem => menuItem.label === label);
    if (menuIndex >= 0) {
      this.menuItems[menuIndex].visible = false;
      this.subject.next(this.menuItems.filter(item => item.visible === true));
    }
  }

  // Shows menu item with specified label
  showMenuItemByLabel(label: string) {
    const menuIndex = this.menuItems.findIndex(menuItem => menuItem.label === label);
    if (menuIndex >= 0) {
      this.menuItems[menuIndex].visible = true;
      this.subject.next(this.menuItems.filter(item => item.visible === true));
    }
  }


  // Show or hide menu items by label
  showOrHideMenuItems(label: string, show: boolean) {
    if (show === false) {
      this.hideMenuItemByLabel(label);
    } else {
      this.showMenuItemByLabel(label);
    }
  }

  async fetchAppFeaturesConfiguration() {
    this.featureFlags = await this.dispatchService.getFeatureFlags();
    this.showWorkdayELearning = this.cardService.canShowWorkdayTile(this.featureFlags);
    this.showOrHideMenuItems('Workday Learning', this.showWorkdayELearning);
    this.showGamification = this.featureFlags?.flags?.IsGamificationEnabled ? this.featureFlags.flags.IsGamificationEnabled : false;      

  }

  // helplink: https://www.codegrepper.com/code-examples/javascript/typescript+get+number+of+days+between+two+dates
  getDifferenceInDays(orientationDate: Date, currentDate: Date) {
    const diffInMs = Math.abs(new Date(currentDate).getTime() - new Date(orientationDate).getTime());
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  determineWelcomeExperience(orientationDetails: OrientationDetails) {
    const currentDate = new Date();
    const orientationDaysDifference = this.getDifferenceInDays(orientationDetails.orientationDate, currentDate);
    if (orientationDaysDifference > WernerWelcomeExperience.welecomeScreenNumberOfDays) {
      this.hideMenuItemByLabel('Welcome');
    }
  }

  /* Werner Welcome Experience is a Business rule that states that the Welcome option on the menu should be shown
     to Drivers, only for 5 days from Orientation Date. After that, the Welcome option should not be displayed.
  */
  async initiateWernerWelcomeExperience() {
    // get orientation details from storage
    const orientationDetailsJson = await Preferences.get({ key: StorageConstants.ORIENTATION_DETAILS });
    if (orientationDetailsJson.value === null) {
      // we do not have orientation detais in storage - fetch it from the API.
      this.requestService.orientationGet(this.identityKey).subscribe(response => {
        if (response !== null) {
          // we have a valid response - we can determine orientation experience
          Preferences.set({ key: StorageConstants.ORIENTATION_DETAILS, value: JSON.stringify(response) });
          this.orientationDetails = response;
          this.determineWelcomeExperience(this.orientationDetails);
        } else {
          // response is null - since we cannot determine welcome experience, remove welcome option from menu.
          this.hideMenuItemByLabel('Welcome');
        }
      });
    } else {
      // we have orientation details in Storage. Retrieve and process it
      this.orientationDetails = JSON.parse(orientationDetailsJson.value);
      this.determineWelcomeExperience(this.orientationDetails);
    }
  }

  async setupConfigurationAndMenuItems() {
    await this.fetchAppFeaturesConfiguration();
    await this.getUserInfo();
    this.setAdminSubMenuItems();
  }

  private async routeChanged(): Promise<void> {
    this.hideMenuItemByLabel('Profile');
    await this.getUserInfo();
    await this.initiateWernerWelcomeExperience();
  }

  ngOnDestroy(): void {
      this.subscriptions$.unsubscribe();
  }
}
